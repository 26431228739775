import React from 'react';
import { Button } from '@chakra-ui/react';
const recipient = 'ranjan.relan@agentanalytics';
const subject = 'Book a Demo with Agentanalytics';

 const body = `
  Hi Ranjan,

  I'd like to book a demo with Agent Analytics to learn more about your offerings.

  Looking forward to scheduling a time with you.

  Best regards,
  [Your Name]

  -- 
  [Your Company Name]
  `;

export const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

const MailButton = () => {

  return (
    <Button
      as="a"
      href={mailtoLink}
      colorScheme="teal"
      size="md"
      target="_blank"
      rel="noopener noreferrer"
    >
      Book a Demo
    </Button>
  );
};

export default MailButton;
