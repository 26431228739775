import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import backdemo from "../../Assets/backdemo.png";
import ThanksCard from "./ThanksCard";

function ContactPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      // minH="100vh"
      bgGradient="linear(to-b,#0A0A0F,#0A0A0F,#0A0A0F,#0A0A0F,#0A0A0F,#0A0A0F, #0A0A0F, #1F1F2E, #3E3D58, #563D7C, #6A3EA3,#FFFFFF)"
      position="relative"
      px={{ base: 2, md: 4, lg: 8 }} // Adjusted padding for smaller screens
      py={{ base: 6, md: 8, lg: 16 }} // Adjusted padding for smaller screens
      color="white"
      w="100%" // Ensuring full width on all screens
      margin={"auto"}
      boxSizing={"border-box"}
    >
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }} // One column for small screens, two for medium and larger
        gap={{ base: 4, md: 8, lg: 16 }} // Adjusting gap for better responsiveness
        // maxW="100%"
        // mx="auto"
        position="relative"
        zIndex="2"
        w="100%" // Ensuring grid takes full width
      >
        {/* Left Section */}
        <Box w="100%" pl={{ base: 0, md: 12 }} boxSizing={"border-box"}>
          <Text
            w={"fit-content"}
            px={2}
            py={1}
            border={"1px solid #4D2F8C"}
            fontSize="md"
            color="purple.300"
            mb={1}
            borderRadius="12px"
          >
            Enterprise Ready
          </Text>
          <Stack spacing={12}>
            <Box>
              <Heading lineHeight="1.1" as="h2" size="2xl">
                <Text>Build</Text>
                <Text>
                  <Text
                    as="span"
                    bgGradient="linear-gradient(90deg, #E270FF 0.08%, #6BA6FF 60%)"
                    bgClip="text"
                  >
                    Agents
                  </Text>{" "}
                  to
                </Text>

                <Text>Automate Your</Text>
                <Text
                  bgGradient="linear-gradient(to-r, #FFFFFF , rgba(146, 44, 255, 0.7) 49.93%)"
                  bgClip="text"
                >
                  Business
                </Text>
              </Heading>
            </Box>

            <Stack spacing={2} fontSize="lg">
              <Text
                fontSize={"lg"}
                bgGradient="linear-gradient(to-r, #FFFFFF ,#EFEDFDB2, #EFEDFDB2)"
                bgClip="text"
                wordSpacing="10em"
              >
                ✓ Quick tour of our capabilities
              </Text>
              <Text
                bgGradient="linear-gradient(to-r, #FFFFFF ,#EFEDFDB2, #EFEDFDB2)"
                bgClip="text"
              >
                ✓ Focus on outcomes
              </Text>
              <Text
                bgGradient="linear-gradient(to-r, #FFFFFF ,#EFEDFDB2, #EFEDFDB2)"
                bgClip="text"
              >
                ✓ Personalize as per your needs
              </Text>
            </Stack>
          </Stack>
        </Box>

        {/* Right Section - Form */}
        <Box
          backdropFilter="blur(10px)"
          w="100%"
          bg="rgba(0, 0, 0, 0.8)"
          p={{ base: 6, md: 8 }}
          borderRadius="lg"
        >
          <Heading as="h2" size="xl">
            Let’s connect
          </Heading>
          <Text mb={6}>
            Connect with us and accelerate your vision! Let’s collaborate to
            turn ideas into impactful solutions—reach out today, and let’s get
            moving.
          </Text>
          <Stack spacing={3}>
            <Flex direction={{ base: "column", md: "row" }} gap={3}>
              <Input
                borderColor="gray.600"
                borderWidth="1px"
                placeholder="First Name*"
                variant="filled"
                bg="gray.800"
                _placeholder={{ color: "gray.400" }}
                w="full"
              />
              <Input
                borderColor="gray.600"
                borderWidth="1px"
                placeholder="Last Name*"
                variant="filled"
                bg="gray.800"
                _placeholder={{ color: "gray.400" }}
                w="full"
              />
            </Flex>
            <Input
              borderColor="gray.600"
              borderWidth="1px"
              placeholder="Work Email*"
              variant="filled"
              bg="gray.800"
              _placeholder={{ color: "gray.400" }}
            />
            <Flex direction={{ base: "column", md: "row" }} gap={3}>
              <Input
                borderColor="gray.600"
                borderWidth="1px"
                placeholder="Company Name"
                variant="filled"
                bg="gray.800"
                _placeholder={{ color: "gray.400" }}
                w="full"
              />
              <Input
                borderColor="gray.600"
                borderWidth="1px"
                placeholder="Job Title"
                variant="filled"
                bg="gray.800"
                _placeholder={{ color: "gray.400" }}
                w="full"
              />
            </Flex>
            <Select
              bg="gray.800"
              color="gray.400"
              borderColor="gray.600"
              borderWidth="1px"
              placeholder="How did you hear about AgentAnalytics.AI?"
              _placeholder={{ color: "gray.400" }}
              iconColor="gray.500"
              focusBorderColor="gray.600"
              _hover={{ borderColor: "gray.500" }}
              sx={{
                option: {
                  bg: "gray.800",
                  color: "gray.400",
                  _hover: { bg: "gray.700" },
                },
              }}
            >
              <option value="Google/Meta Ads">Google/Meta Ads</option>
              <option value="Google Search">Google Search</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Twitter">Twitter</option>
              <option value="Youtube">Youtube</option>
              <option value="Partner Network">Partner Network</option>
              <option value="ProductHunt/G2 marketplace">
                ProductHunt/G2 marketplace
              </option>
              <option value="Affiliate/Influencer Promotions">
                Affiliate/Influencer Promotions
              </option>
              <option value="Someone reached out">Someone reached out</option>
              <option value="Community Groups">Community Groups</option>
              <option value="Other">Other</option>
            </Select>

            <Textarea
              bg="gray.800"
              borderColor="gray.600"
              borderWidth="1px"
              placeholder="Please provide any relevant details you want to share before the demo"
              variant="filled"
              _placeholder={{ color: "gray.400" }}
            />

            <Text fontSize="xs" color="gray.400">
              By submitting the form, I have read and agree to the
              AgentAnalytics.AI Terms and Conditions and understand that my
              personal information is processed in accordance with the Privacy
              Notice.
            </Text>

            <Button
              color={"white"}
              bgGradient={"linear-gradient(90deg, #763AF5 0%, #A604F2 117.18%)"}
              w="full"
              onClick={onOpen}
              _hover={{
                color:"white",
                bg:"gray.600"
              }}
            >
              Schedule AgentAnalytics.AI Demo
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose} bg="black">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton color={"white"} />
          <ModalBody bg={"black"}>
            <ThanksCard />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ContactPage;

