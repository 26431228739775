import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { footerData } from "./footerdata.js";
import FooterLinksList from "./FooterLinksList.jsx";

function FooterLinks() {
  return (
    <Box boxSizing={"border-box"}>
      <HStack spacing={{ base: "12px", md: "16px" }} padding={{ base: "10px", md: "0" }}>
        {/* <Box>
          <Text color={"#FFFFFF"} fontSize={"16px"} fontWeight={500}>
            Product
          </Text>
          <FooterLinksList data={footerData.Product} />
        </Box> */}
        <Box>
          <Text color={"#FFFFFF"} fontSize={{ base: "12px", md: "16px" }} fontWeight={500}>
            Company
          </Text>
          <FooterLinksList data={footerData.Company} />
        </Box>
        <Box>
          <Text color={"#FFFFFF"} fontSize={{ base: "12px", md: "16px" }} fontWeight={500}>
            Resources
          </Text>
          <FooterLinksList data={footerData.Pricing} />
        </Box>
        {/* <Box>
          <Text color={"#FFFFFF"} fontSize={"16px"} fontWeight={500}>
            Legal
          </Text>
          <FooterLinksList data={footerData.Legal} />
        </Box> */}
      </HStack>
    </Box>
  );
}

export default FooterLinks;
