import globe from "../../Assets/homepage/banner/globe.png"
import usp1 from "../../Assets/landingpage/usp/usp1.png"
import usp2 from "../../Assets/landingpage/usp/usp2.png"
import usp3 from "../../Assets/landingpage/usp/usp3.png"
import usp4 from "../../Assets/landingpage/usp/usp4.png"
import usp5 from "../../Assets/landingpage/usp/usp5.png"
import usp6 from "../../Assets/landingpage/usp/usp6.png"
import usp7 from "../../Assets/homepage/usp/usp7.png"
import usp8 from "../../Assets/homepage/usp/vectorLake.png"
import ind1 from "../../Assets/homepage/industries/industries1.png"
import ind2 from "../../Assets/homepage/industries/ind2.png"
import ind3 from "../../Assets/homepage/industries/ind3.png"
import ind4 from "../../Assets/homepage/industries/ind4.png"
import ind5 from "../../Assets/homepage/industries/ind5.png"
import ind6 from "../../Assets/homepage/industries/ind6.png"
import ind7 from "../../Assets/homepage/industries/ind7.png"
import ind8 from "../../Assets/homepage/industries/ind8.png"
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';


export const bannerData=[
    {
        title:"Security & Privacy",
        desc:"Dockerize in your premise or on private cloud",
        topButton:"Enterprise Ready",
        button1:"Get in touch",
        button2:"Learn more",
        img:globe
    },
    {
        title:"Security & Privacy",
        desc:"Dockerize in your premise or on private cloud",
        topButton:"Enterprise Ready",
        button1:"Get in touch",
        button2:"Learn more",
        img:globe
    },
    {
        title:"Security & Privacy",
        desc:"Dockerize in your premise or on private cloud",
        topButton:"Enterprise Ready",
        button1:"Get in touch",
        button2:"Learn more",
        img:globe
    },
    {
        title:"Security & Privacy",
        desc:"Dockerize in your premise or on private cloud",
        topButton:"Enterprise Ready",
        button1:"Get in touch",
        button2:"Learn more",
        img:globe
    }
]


// export const uspData = [
//     {
//       title: "Early Start",
//       description: "At the forefront of innovation, we pioneered Agentic Technology long before it became the industry standard, shaping the future of intelligent systems.",
//       icon: usp1, 
//     },
//     {
//       title: "Privacy",
//       description: "We empower you to deploy on your private enterprise cloud, ensuring that privacy and security are not just priorities, but the foundation of your digital infrastructure.",
//       icon: usp2, 
//     },
//     {
//       title: "No LLM License",
//       description: "Deploy over 200+ open-source LLMs on the cloud, without the burden of licensing, giving you the freedom to scale, adapt, and lead with cutting-edge AI.",
//       icon: usp3, 
//     },
//     {
//       title: "Small Language Model",
//       description: "Build specialized Agents using Small Language Models, designed to deliver targeted, efficient, and effective customer interactions.",
//       icon: usp4, 
//     },
//     {
//       title: "Digital Traceability",
//       description: "Leverage our cutting-edge LINE Technology to ensure digital traceability, providing unparalleled transparency and accountability across your entire supply chain.",
//       icon: usp5, 
//     },
//     {
//       title: "Ethical AI Award",
//       description: "Our Responsible AI Co-worker, a proud winner of the Ethical AI Award, embodies our commitment to creating AI that upholds the highest standards of integrity and responsibility.",
//       icon: usp6, 
//     },
//     {
//       title: "No Hallucination",
//       description: "With our GAIA benchmark achieving hallucination rates of less than 1%, we set the standard for accuracy and reliability, far surpassing industry giants. Experience AI that you can trust, without the noise.",
//       icon: usp7, 
//     },
//     {
//       title: "Vector Lake",
//       description: "Unlock the power of our Vector Lake for Retrieval-Augmented Generation (RAG)—enjoy zero hallucination, complete privacy and security, and freedom from vector database license fees, all tailored for your enterprise needs.",
//       icon: usp8, 
//     },
//   ];
  
export const features = [
  {
    title: "Privacy",
    description:
      "We enable deployment on your private enterprise cloud, ensuring privacy and security as the foundation of your digital infrastructure.",
    icon: usp1, // You can replace this with an actual icon if needed
  },
  {
    title: "No LLM License",
    description:
      "Deploy 200+ open-source LLMs on the cloud, free from licensing burdens, empowering you to scale and innovate with cutting-edge AI.",
    icon: usp2,
  },
  {
    title: "Small Language Model",
    description:
      "Build specialized agents with Small Language Models for targeted and efficient customer interactions.",
    icon: usp3,
  },
  {
    title: "Digital Traceability",
    description:
      "Leverage cutting-edge LINE Technology for digital traceability, ensuring transparency and accountability across your supply chain.",
    icon: usp4,
  },
  {
    title: "No Hallucination",
    description:
      "Our GAIA benchmark, with under 1% hallucination, sets a new standard for accurate, reliable, and trustworthy AI.",
    icon: usp5,
  },
  {
    title: "Vector Lake",
    description:
      "Unlock Vector Lake for RAG—zero hallucination, full privacy, and no vector database fees, tailored for your enterprise.",
    icon: usp6,
  },
];


  export const categories = [
    {
      title: "Finance & Banking",
      icon:ind1,
      items: ["Customer Segmentation", "Customer Personalization", "Fraud Detection"],
      link: "#"
    },
    {
      title: "Healthcare & Pharma",
      icon:ind2,
      items: ["HCP Segmentation", "Drug Discovery", "Wearable Analytics"],
      link: "#"
    },
    {
      title: "Manufacturing & Production",
      icon:ind3,
      items: ["Predictive Maintenance", "Supply Chain Optimization", "Quality Control"],
      link: "#"
    },
    {
      title: "Construction & Real Estate",
      icon:ind4,
      items: ["Smart Buildings", "Construction Site Monitoring", "Real Estate Analytics"],
      link: "#"
    },
    {
      title: "Energy & Utilities",
      icon:ind5,
      items: ["Smart Grids", "Predictive Analytics", "Consumption Analytics"],
      link: "#"
    },
    {
      title: "Telecommunications",
      icon:ind6,
      items: ["Network Optimization", "Customer Support Automation", "Data Monetization"],
      link: "#"
    },
    {
      title: "Transportation",
      icon:ind7,
      items: ["Fleet Management", "Smart Traffic Management", "Logistics Optimization"],
      link: "#"
    },
    {
      title: "Media & Communication",
      icon:ind8,
      items: ["Content Recommendation", "Audience Analytics", "Social Media Monitoring"],
      link: "#"
    }
  ];
  

  export const vectorDataLakeData = [
    {
      category: "No DB License Fees",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "No Hallucination",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "No Data Movement",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "Scalability (Big Data)",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "Contextualization",
      vectorDataLake: "Globalized",
      vectorDatabase: "Localized"
    },
    {
      category: "Digital Traceability",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "Low Effort",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    },
    {
      category: "Low Cost",
      vectorDataLake: CheckIcon,
      vectorDatabase: CloseIcon
    }
  ];
  

  export const BannerOptions=[
    "NO HALLUCINATION",
    "PRIVATE API",
    "HIGHLY SCALABLE"
  ]