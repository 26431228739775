import React from 'react'
import { List, ListItem, Image, Link, Box, Text } from '@chakra-ui/react';
import rightIcon from "../../Assets/pricingpage/rightIcon.png"

function PriceListing({ packageAvailability }) {
    return (
        <Box>
            <List display={"flex"} flexDirection={"column"} >
                {
                    packageAvailability && packageAvailability.map((ele) => (
                        <ListItem lineHeight={"35px"} fontSize={"12px"} alignItems={"center"}>
                            {/* <Link fontSize={"12px"} href="https://example.com/item1" isExternal> */}
                                <Image src={rightIcon} alt="linkdine" display="inline" w={"16px"} mr={1} />
                                {/* <Text fontSize={"14px"} >{ele}</Text> */}
                                {ele}
                            {/* </Link> */}
                        </ListItem>
                    ))
                }
            </List>
        </Box>
    )
}

export default PriceListing