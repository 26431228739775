import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import InformationTechnology from './InformationTechnology';

function AllTasksDepartment() {
  // Adjust the width of the container based on screen size
  const containerWidth = useBreakpointValue({ base: '100%', md: '90%' });

  return (
    <Box w={containerWidth} m="auto" p={4}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Information Technology</Tab>
          <Tab>Sale</Tab>
          <Tab>Production Planning Control</Tab>
          <Tab>Production Engineer</Tab>
          <Tab>QA</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <InformationTechnology />
          </TabPanel>
          <TabPanel>
            <p>Sale</p>
          </TabPanel>
          <TabPanel>
            <p>Production Planning Control</p>
          </TabPanel>
          <TabPanel>
            <p>Production Engineer</p>
          </TabPanel>
          <TabPanel>
            <p>QA</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default AllTasksDepartment;
