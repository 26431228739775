import { Box, Flex, Image } from '@chakra-ui/react'
import React from 'react'
import carbon_enterprise from "../../Assets/pricingpage/carbon_enterprise.png"
import carbon_enterprise_new from "../../Assets/pricingpage/carbon_enterpris_new.png"

function EnterpriseImage() {
  return (
    <Box>
        <Flex textAlign={"center"} alignItems={"center"}>
            <Image w={"25px"} src={carbon_enterprise} mr={1}/>
            <Image w={"40px"} h={"20px"}  src={carbon_enterprise_new} />
        </Flex>
    </Box>
  )
}

export default EnterpriseImage