import { Box, Button, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import thanksIcon from "../../Assets/thanksIcon.png";
import { useNavigate } from "react-router-dom";

function ThanksCard() {
    const navigate=useNavigate()
    const backToSiteHandle=()=>{
        navigate("/")
    }
  return (
    <VStack
      textAlign={"center"}
      alignItems={"center"}
      bg={"black"}
      color={"white"}
    >
      <Image src={thanksIcon} />
      <Text fontSize={"x-large"} fontWeight={500}>
        Thanks for reaching out!
      </Text>
      <Text>
        We've received your request! Our team will be in touch within the next
        24 hours.
      </Text>
      <Button
        color={"white"}
        bgGradient={"linear-gradient(90deg, #763AF5 0%, #A604F2 117.18%)"}
        w="fit-content"
        mb={"20px"}
        _hover={{
            color:"white",
            bg:"gray.700"
        }}
        onClick={backToSiteHandle}
      >
        Back to site
      </Button>
    </VStack>
  );
}

export default ThanksCard;
