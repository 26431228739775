// LandingPage.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import Banner from './Banner';
import PartnersDetails from './PartnersDetails';
import Offers from './Offers';
const Partners = () => {
  return (
    <Box w={"100%"} boxSizing={"border-box"} overflowX="hidden" bg={"black"}>
    <Banner />
    <Box boxSizing={"border-box"} w={"90%"} m={"auto"} mt={"35px"} bg={"black"}>
      <Text fontWeight={500} textAlign={"center"} letterSpacing={"wide"} color={"white"}>
        We're inviting visionary business partners to join us. Together, we'll empower enterprises to unlock AI's potential while upholding the highest standards of data security, privacy, and ethical practices.
      </Text>
    </Box>
    <Box>
      <PartnersDetails />
    </Box>
    <Box boxSizing={"border-box"} w={"90%"} m={"auto"} mt={"60px"} mb={"80px"}> 
      <Offers />
    </Box>
  </Box>
  

  );
};

export default Partners;
