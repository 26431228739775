import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import BannerComponent from "./BannerComponent";
import { bannerData } from "./BannerData.js";
import VerticalAgents from "./VerticalComp.jsx";
import VerticalComp2 from "./VerticalComp2.jsx";
import VerticalComp3 from "./VericalComp3.jsx";

let data = [
  { title: "aisals" },
  { title: "Waveflow" },
  { title: "VectorLake" },
];

const SlidingBanner = () => {
  return (
    <Box w="100%">
      <Carousel
        showArrows={false}
        autoPlay={true}
        infiniteLoop={true}
        emulateTouch={true}
        // showThumbs={false}
      >
        {data &&
          data.map((item, index) => (
            <Box
              key={index}
              position="relative"
              w={"110vw"}
              h={{ base: "65vh", md: "96" }}
              // p={{ base: 4, md: 0 }}
            >
              {item.title === "aisals" && <VerticalAgents />}
              {item.title === "Waveflow" && <VerticalComp2 />}
              {item.title === "VectorLake" && <VerticalComp3 />}
            </Box>
          ))}
      </Carousel>
    </Box>
  );
};

export default SlidingBanner;
