import { Flex, Text, Image, Circle, VStack, Box } from "@chakra-ui/react";
import { useState } from "react";
import logo from "../../Assets/homepage/logo.png";
import offer1 from "../../Assets/partenrs/offer1.png";
import offer2 from "../../Assets/partenrs/offer2.png";
import offer3 from "../../Assets/partenrs/offer3.png";
import offer4 from "../../Assets/partenrs/offer4.png";

const steps = [
  {
    title: "Lucrative Channel Partnership Agreements",
    lightIcon: logo,
    darkIcon: offer1,
  },
  {
    title: "Comprehensive Training and Support",
    lightIcon: logo,
    darkIcon: offer2,
  },
  {
    title: "Joint Marketing and Sales Opportunities",
    lightIcon: logo,
    darkIcon: offer3,
  },
  {
    title: "Access to Cutting-Edge AI Technology",
    lightIcon: logo,
    darkIcon: offer4,
  },
];

const Offers = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Box>
      <VStack textAlign={"center"} >
        <Box textAlign={"center"} fontWeight={600} fontSize={"xx-large"}>
          <Text as={"spane"} color={"white"}>
            What we{" "}
            <Text
              as={"span"}
              bgGradient={"linear(to-r,#8860D0, #5AB9EA)"}
              bgClip={"text"}
            >
              offer?
            </Text>
          </Text>
        </Box>
        <Text fontSize={"16px"} color={"#8A94A8"}>
          Let's do great work together
        </Text>
      </VStack>
      <Flex justifyContent="center" alignItems="center" mt={10} mb={10}>
        {steps.map((step, index) => (
          <Flex key={index} alignItems="center">
            <VStack spacing={2} color={"white"}>
              <Circle
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                size="50px"
                bg="white"
                border="2px solid lightgray"
                transition="transform 0.3s ease, background-color 0.3s ease"
                transform={hoveredIndex === index ? "scale(1.5)" : "scale(1)"}
              >
                <Image
                  src={hoveredIndex === index ? step.darkIcon : step.lightIcon}
                  boxSize="40px"
                  alt={step.title}
                  filter={
                    hoveredIndex === index
                      ? "brightness(0.5)"
                      : "brightness(0.8)"
                  }
                />
              </Circle>
              <Text fontWeight="bold" textAlign="center" color={"white"}>
                {step.title}
              </Text>
            </VStack>
            {index < steps.length - 1 && (
              <Box
                height="1px"
                width="50px"
                borderBottom="2px dotted blue"
                mx={4}
                alignSelf="center"
              />
            )}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default Offers;
