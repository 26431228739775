import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

function PricingTitle({setToggle,toggle}) {
  

  const billingToggle = () => {
    setToggle(!toggle);
  };

  return (
    <Box  w={"100%"} boxSizing="border-box" textAlign={"center"}>
      <VStack spacing={3}>
        <Box>
          <Text color={"#FFFFFF"} fontSize={"40px"} fontWeight={500}>
            Pricing
          </Text>
        </Box>
        <Box>
          <Text color={"#FFFFFF"} fontSize={"14px"}>
            Choose the right plan to meet your needs <br /> and start optimizing
            today.
          </Text>
        </Box>
        {/* <Box>
          <FormControl display="flex" alignItems="center">
            <Switch onChange={billingToggle} size="sm" />
            <FormLabel
              ml={2}
              fontSize={"14px"}
              color={"#3D475C"}
              htmlFor="bill"
              mb="0"
            >
              {toggle ? "Billed Monthly" : "Billed Annually"}
            </FormLabel>
          </FormControl>
        </Box> */}
      </VStack>
    </Box>
  );
}

export default PricingTitle;
