import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useDisclosure } from "@chakra-ui/react"
import { useState } from "react"

export function BackdropExample() {
  console.log("backdrop")
    const OverlayOne = () => (
      <ModalOverlay
        bg='blackAlpha.300'
        backdropFilter='blur(5px) hue-rotate(90deg)'
      />
    )
  
  
  
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [overlay, setOverlay] = useState(<OverlayOne />)
  
    return (
      <>
        <Button
        w={'100px'}
        bgGradient='linear(to-r, rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))' 
        color={"white"}
          onClick={() => {
            setOverlay(<OverlayOne />)
            onOpen()
          }}
        >
          Apply Now
        </Button>
        
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
          {overlay}
          <ModalContent w={"50%"} maxW={"1200px"}>
            <ModalCloseButton />
            <ModalBody p={"25px"}>
              <Box>
                <Box mb={"20px"} textAlign={"center"}>
                    <Text fontWeight={"bold"}>Full Stack MERN Developer</Text>
                    <Text color={"rgba(138, 148, 168, 1)"}>Hyderabad, India</Text>
                </Box>
                <Text>
                If you're excited about working in a high-impact startup and want to contribute to building cutting-edge platforms, we'd love to hear from you! Please send your resume and a brief cover   letter   to   mamta@AgentAnalytics.AI   with   the   subject   line   "Full   Stack   Architect Application - [Your Name]."
          <Spacer mb={"20px"}/>
AgentAnalytics.AI is an equal-opportunity employer. We celebrate diversity and   are committed to creating an inclusive environment for all employees.
                </Text>
              </Box>
            </ModalBody>
            {/* <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
      </>
    )
  }