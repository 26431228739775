import React from "react";
import {
  Box,
  Text,
  Image,
  useBreakpointValue,
  keyframes,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { logos } from "./logo.js";

// Define keyframes for smooth sliding animation
const slideIn = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const LogosSlider = () => {
  // Determine whether to use a carousel based on screen size
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Settings for the react-slick carousel
  const carouselSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <Box
      // zIndex={-1}
      mb={"5%"}
      position={"relative"}
      // zIndex={10}
      bg="linear-gradient(to top right, black,black ,black,black ,black,black ,black,black,#070821 ,#19113F, #19113F)"
    >
      <Box
        position={"relative"}
        zIndex={10}
        textAlign={"center"}
        bg={"transparent"}
      >
        <Text fontSize={"30"} fontWeight={400} color={"white"}>
          Trusted by the best.
        </Text>
      </Box>
      <Box
        w={"90%"}
        h={"100px"}
        margin={"auto"}
        // bg={"rgb(9,4,13)"}
        bg={"transparent"}
        position="relative"
        overflow="hidden"
      >
        {isMobile ? (
          // Mobile view with a carousel
          <Box>
            <Slider {...carouselSettings}>
              {logos.map((logo, index) => (
                <Box key={index} textAlign="center">
                  <Image
                    src={logo.src}
                    alt={logo.alt}
                    h="50px"
                    mx="auto"
                    display="inline-block"
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        ) : (
          // Desktop view with smooth sliding animation
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            position="relative"
            overflow="hidden"
            whiteSpace="nowrap"
            _before={{
              content: '""',
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              width: "100px",
              bgGradient:
                "linear(to-r, black,black,black, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
              zIndex: 1,
            }}
            _after={{
              content: '""',
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              width: "100px",
              bgGradient:
                "linear(to-l, black,black,black, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
              zIndex: 1,
            }}
          >
            <Box
              display="inline-block"
              animation={`${slideIn} 60s linear infinite`}
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                gap: 50,
              }}
            >
              {logos.concat(logos).map((logo, index) => (
                <Image
                  key={index}
                  src={logo.src}
                  alt={logo.alt}
                  h="50px"
                  display="inline-block"
                  mx={8}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LogosSlider;
