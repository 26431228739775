import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Stack,
  Icon,
  Divider,
  HStack,
  Image,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import WaveFlowListing from "./WaveFlow/WaveFlowListing";
import EnterpriseListing from "./EnterpriseListing";
const convrsationDataList = [
  "Relevancy (Global)",
  "Ease of Integration (CRM, SharePoint) citation",
  "Customization",
  "SDKs available",
  "LINE citation",
];
const SearchCard = () => {
  return (
    <Box
      //   bgGradient="linear(to-b, purple.800, black)"
      bg={"transparent"}
      border="1px"
      borderColor="purple.500"
      borderRadius="lg"
      p={4}
      w={["100%", "100%", "35%"]}
    >
      <VStack align={"start"} justifyContent={"flex-start"} spacing={0} mb={3}>
        <HStack w={"100%"}>
          <Text color="#FFF" fontSize="lg">
            Enterprise Search{" "}
          </Text>
          {/* <Image w={"40px"} h={"20px"} src={carbon_enterprise_new} /> */}
        </HStack>
        <HStack mb={"10px"}>
          <Text fontSize="sm" color={"rgba(138, 148, 168, 1)"}>
            Free
          </Text>
          {/* <Image w={"20px"} h={"20px"} src={carbon_enterprise} /> */}
        </HStack>
        <Divider borderColor="rgba(40, 39, 41, 1)" />
      </VStack>

      <VStack align="start" spacing={4}>
        <EnterpriseListing packageAvailability={convrsationDataList} />
      </VStack>
      <Button
        boxShadow="
          inset rgba(255, 255, 255, 0.03) 0px 1px 2px, 
          inset rgba(255, 255, 255, 0.04) 0px 2px 4px, 
          inset rgba(255, 255, 255, 0.03) 0px 4px 8px, 
          inset rgba(255, 255, 255, 0.04) 0px 8px 16px, 
          inset rgba(255, 255, 255, 0.03) 0px 16px 32px, 
          inset rgba(255, 255, 255, 0.04) 0px 32px 64px"
        background="rgba(61, 61, 61, 0.4)"
        color="#FFFFFF"
        backgroundColor="transparent"
        size="sm"
        border="1px solid #C1C8E4"
        w="100%"
        _hover={{
          bg: "gray.400", // Change background on hover
          color: "black", // Change text color on hover
          transform: "scale(1.05)", // Slightly enlarge the button
          boxShadow: "0 4px 12px rgba(74, 144, 226, 0.4)", // Add shadow
        }}
      >
        Join waitlist
      </Button>
    </Box>
  );
};

export default SearchCard;
