import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import PriceDetailsComponent from "./PriceDetailsComponent";
import { pricingData } from "./pricingData";

function PricingDetails({toggle}) {
  return (
    <Box   boxSizing="border-box" w={"80%"} m={"auto"}>
      <Flex gap={3}>
        {/* <Box
          backdropFilter="blur(10px)"
          borderRadius={"10px"}
          flex="1"
          p="15px"
          border="1px solid lightgray"
          _hover={{
            boxShadow:
              "0px 2px 4px rgba(136, 96, 208, 1), " +
              "0px 6px 8px rgba(136, 96, 208, 1), " +
              "0px 6px 32px rgba(136, 96, 208, 1)",
          }}
        >
          <PriceDetailsComponent toggle={toggle} data={pricingData.free} />
        </Box> */}
        <Box
          backdropFilter="blur(10px)"
          borderRadius={"10px"}
          flex="1"
          p="15px"
          border="1px solid lightgray"
          _hover={{
            boxShadow:
              "0px 2px 4px rgba(136, 96, 208, 1), " +
              "0px 6px 8px rgba(136, 96, 208, 1), " +
              "0px 6px 32px rgba(136, 96, 208, 1)",
          }}
        >
          <PriceDetailsComponent toggle={toggle} data={pricingData.basic} />
        </Box>
        <Box
          backdropFilter="blur(10px)"
          borderRadius={"10px"}
          flex="1"
          p="15px"
          border="1px solid lightgray"
          _hover={{
            boxShadow:
              "0px 2px 4px rgba(136, 96, 208, 1), " +
              "0px 6px 8px rgba(136, 96, 208, 1), " +
              "0px 6px 32px rgba(136, 96, 208, 1)",
          }}
        >
          <PriceDetailsComponent toggle={toggle} data={pricingData.Buisness} />
        </Box>
        <Box
          boxShadow={
            "0px 2px 4px rgba(136, 96, 208, 1), " +
            "0px 6px 8px rgba(136, 96, 208, 1), " +
            "0px 6px 32px rgba(136, 96, 208, 1)"
          }
          borderRadius={"10px"}
          flex="1"
          p="15px"
          border="1px solid lightgray"
          backdropFilter="blur(10px)"
        >
          <PriceDetailsComponent toggle={toggle} data={pricingData.Enterprise} />
        </Box>
      </Flex>
    </Box>
  );
}

export default PricingDetails;
