import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import WaveFlow from "./WaveFlow/WaveFlow";
import EnterpriseSearch from "./EnterpriseSearch";
import blacksquer from "../../../Assets/pricingpage/darkmode/blacksquer.png";
import Pricing from "../Pricing";
import EnterPriseMain from "../EnterPriseMain";
import WaveMain from "../WaveMain";
import ConversationAi from "../ConversationAiMain";

function PricingMainPage() {
  return (
    <Box
      bg={"black"}
      w={"100%"}
      boxSizing="border-box"
      color={"#FFFFFF"}
      py={8}
    >
      {/* Header Section */}
      <VStack m={"auto"} w={"90%"} spacing={4}>
        <Box>
          <Text fontSize={"40px"} fontWeight={500}>
            Pricing
          </Text>
        </Box>
        <Box>
          <Text fontSize={"sm"}>
            Choose the right plan to meet your needs today.
          </Text>
        </Box>
      </VStack>

      {/* Tabs Section */}
      <Tabs
        variant="unstyled"
        colorScheme="green"
        bg="black.800"
        m="auto"
        borderRadius="lg"
        // border="1px solid rgba(95, 98, 101, 0.6)"
        maxWidth="100%"
        mt={1}
        p={4}
        defaultIndex={1}
      >
        {/* Tab List */}
        <TabList
          bg={"gray.800"}
          display="flex"
          justifyContent="center"
          gap={1}
          border={"1px solid rgba(95, 98, 101, 1)"}
          w={"fit-content"}
          m={"auto"}
          borderRadius={"lg"}
        >
          <Tab
            color="rgba(95, 98, 101, 1)"
            _selected={{
              color: "white",
              bgGradient: "linear(to-r, blue.500, purple.500)",
              borderRadius: "lg",
            }}
            px={2}
            py={1}
          >
            Enterprise Search
          </Tab>
          <Tab
            color="rgba(95, 98, 101, 1)"
            _selected={{
              color: "white",
              bgGradient: "linear(to-r, blue.500, purple.500)",
              borderRadius: "lg",
            }}
            px={2}
            py={1}
          >
            Wave Flow
          </Tab>
          <Tab
            color="rgba(95, 98, 101, 1)"
            _selected={{
              color: "white",
              bgGradient: "linear(to-r, blue.500, purple.500)",
              borderRadius: "lg",
            }}
            px={2}
            py={1}
          >
            Conversational AI
          </Tab>
        </TabList>

        {/* Tab Panels */}
        <TabPanels mt={2}>
          <TabPanel>
            <EnterPriseMain />
          </TabPanel>
          <TabPanel>
            <WaveMain />
          </TabPanel>
          <TabPanel>
            <ConversationAi />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box
        as="img"
        src={blacksquer}
        alt="Animated Ring"
        position="absolute"
        bottom="-6"
        left="0"
        width="150px"
        height="150px"
        zIndex="-1"
      />
    </Box>
  );
}

export default PricingMainPage;
