import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import SearchCard from "./SearchCard";
import ComparisonTable from "./ComparisonTable";

const EnterpriseSearch = () => {
  return (
    <Box
      backdropFilter="blur(10px)"
      color="white"
      minH="100vh"
      bg={"black"}
      boxSizing={"border-box"}
      background={"transparent"}
    >
      <Box textAlign="center" mb={8} color={"rgba(185, 189, 198, 1)"}>
        <Heading fontSize="md" mb={2}>
          LINE for Enterprise Search
        </Heading>
        <Text fontSize={"small"}>
          Agent Analytics proprietary technology with global optimization
          (larger content) with LINE citation technology
        </Text>
      </Box>
      <Flex
        direction={["column", "column", "row"]}
        justify="space-between"
        align="center"
        gap={1}
        w={"90vw"}
        margin={"auto"}
      >
        {/* Left Card */}
        <SearchCard />
        <Box w="0.5px" bg="#737373" mx="4" alignSelf="stretch"></Box>
        {/* Comparison Table */}
        <ComparisonTable />
      </Flex>
      <Box
        w={"90vw"}
        margin={"auto"}
        mt={"5%"}
        border={"1px solid"}
        p={2}
        borderColor="purple.500"
      >
        <Text fontStyle="italic" fontSize={"small"}>
          LINE Citation
        </Text>
        <Text fontSize={"small"} color={"rgba(185, 189, 198, 1)"}>
          Line citation ensures precise referencing by directing readers to
          specific lines in legal documents, research papers, or texts. It
          enhances clarity and allows for quick verification, making it an
          essential tool for accurate documentation and analysis.
        </Text>
      </Box>
    </Box>
  );
};

export default EnterpriseSearch;
