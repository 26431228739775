import math from "../../Assets/landingpage/feature/math.png";
import cn from "../../Assets/landingpage/feature/cn.png";
import gov from "../../Assets/landingpage/feature/gov.png";
import Microsoft from "../../Assets/landingpage/feature/microsoft.png";
import THub from "../../Assets/landingpage/feature/thub.png";

export const logos = [
  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },
  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },
  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },

  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },

  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },

  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },

  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },

  { src: math, alt: "Math" },
  { src: Microsoft, alt: "Microsoft" },
  { src: gov, alt: "Science & Tech" },
  { src: THub, alt: "THub Logo" },
  { src: cn, alt: "CN" },
];
