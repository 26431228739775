import React from "react";
import { List, ListItem, Image, Link, Box, Text } from "@chakra-ui/react";
import rightIcon from "../../../../Assets/pricingpage/rightIcon.png";

function WaveFlowListing({ packageAvailability }) {
  return (
    <Box mb={"10%"}>
      <List display={"flex"} flexDirection={"column"}>
        {packageAvailability &&
          packageAvailability.map((ele) => (
            <ListItem
              color={"rgba(255, 255, 255, 1)"}
              lineHeight={"35px"}
              fontSize={"12px"}
              alignItems={"center"}
            >
              <Image
                src={rightIcon}
                alt="linkdine"
                display="inline"
                w={"16px"}
                mr={1}
              />
              {ele}
            </ListItem>
          ))}
      </List>
    </Box>
  );
}

export default WaveFlowListing;
