import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

export function ApplyNowBtn() {
  const OverlayOne = () => (
    <ModalOverlay
      bg="rgba(0, 0, 0, 0.5)" 
      backdropFilter="blur(3px)" 
    />
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);

  return (
    <>
      <Button
        w="100px"
        bgGradient="linear(to-r, rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
        color="white"
        _hover={{
            bg: "blue.500", 
          }}
        onClick={() => {
          setOverlay(<OverlayOne />);
          onOpen();
        }}
      >
        Apply Now
      </Button>

      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent w="50%" maxW="1200px">
          <ModalCloseButton />
          <ModalBody p="25px">
            <Box>
              <Box mb="20px" textAlign="center">
                <Text fontWeight="bold">Full Stack MERN Developer</Text>
                <Text color="rgba(138, 148, 168, 1)">Hyderabad, India</Text>
              </Box>
              <Text>
                If you're excited about working in a high-impact startup and want to contribute to building cutting-edge platforms, we'd love to hear from you! Please send your resume and a brief cover letter to mamta@AgentAnalytics.AI with the subject line "Full Stack Architect Application - [Your Name]."
              </Text>
              <Box my="20px" />
              <Text>
                AgentAnalytics.AI is an equal-opportunity employer. We celebrate diversity and are committed to creating an inclusive environment for all employees.
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
