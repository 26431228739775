import { Box, Text } from "@chakra-ui/react";
import React from "react";
import IndustrisWork from "../Home/IndustrisWork";

function Industries() {
  return (
    <>
    <IndustrisWork/>
      {/* <Box
        w={"100%"}
        h={"100%"}
        textAlign={"center"}
        objectPosition={"center"}
        alignItems={"center"}
        fontSize={"xxx-large"}
      >
        <Text bgGradient="linear(to-r, #151920, #5AB9EA)" bgClip="text">
          Coming soon ...
        </Text>
      </Box> */}
    </>
  );
}

export default Industries;
