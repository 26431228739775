import React from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Icon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FaRobot, FaBullhorn, FaHeartbeat } from "react-icons/fa";
import aiSalsman from "../../Assets/landingpage/banner/aiSalsman.png";
import aiaddfusion from "../../Assets/landingpage/banner/aiaddfusion.png";
import aicareconnect from "../../Assets/landingpage/banner/aicareconnect.png";
import vectorlake from "../../Assets/landingpage/banner/vectorlake.png";
import vectorlake2 from "../../Assets/landingpage/banner/vectorlake2.png";

const VerticalAgents = ({ carddata }) => {
  console.log("carddata", carddata?.title);
  return (
    <Box
      maxW="70%"
      bgGradient="linear(to-b, #141E30, #243B55)"
      borderRadius="xl"
      color="white"
      boxShadow="lg"
      h={"70%"}
    >
      {/* {carddata?.title == "Vector Lake" && (
        <Box
          w="100%"
          h="80%"
          backgroundImage={`url(${carddata?.img2})`}
          backgroundPosition="bottom"
          backgroundSize="100% 100%"
          backgroundRepeat="no-repeat"
          boxSizing="border-box"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            h="80%" // Ensure Flex takes full height to center vertically
            w="100%" // Ensure Flex takes full width to center horizontally
          >
            <HStack
              alignItems={"center"}
              spacing="2"
              w={"fit-content"}
              border={"1px solid white"}
            >
              <Image src={carddata?.img1} boxSize="8" color="cyan.400" />
              <Text
                fontFamily={"Pixelated Elegance"}
                fontSize="30px"
                w={"fit-content"}
              >
                {carddata?.title}
              </Text>
            </HStack>
          </Flex>
        </Box>
      )}
      {carddata?.title == "Waveflow" && (
        <Box
          w="100%"
          h="80%"
          backgroundImage={`url(${carddata?.img2})`}
          backgroundPosition="bottom"
          backgroundSize="100% 100%"
          backgroundRepeat="no-repeat"
          boxSizing="border-box"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            h="80%" // Ensure Flex takes full height to center vertically
            w="100%" // Ensure Flex takes full width to center horizontally
          >
            <HStack
              alignItems={"center"}
              spacing="2"
              w={"fit-content"}
              border={"1px solid white"}
            >
              <Image src={carddata?.img1} boxSize="8" color="cyan.400" />
              <Text
                fontFamily={"Pixelated Elegance"}
                fontSize="30px"
                w={"fit-content"}
              >
                {carddata?.title}
              </Text>
            </HStack>
          </Flex>
        </Box>
      )} */}

      {/* <Box
        w="100%"
        h="100%"
        backgroundImage={`url(${vectorlake2})`}
        backgroundPosition="bottom"
        backgroundSize="80% 80%"
        backgroundRepeat="no-repeat"
        boxSizing="border-box"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          h="100%" // Ensure Flex takes full height to center vertically
          w="100%" // Ensure Flex takes full width to center horizontally
        >
          <HStack
            alignItems={"center"}
            spacing="2"
            w={"fit-content"}
            border={"1px solid white"}
          >
            <Image src={vectorlake} boxSize="8" color="cyan.400" />
            <Text
              fontFamily={"Pixelated Elegance"}
              fontSize="30px"
              w={"fit-content"}
            >
              Vector Lake
            </Text>
          </HStack>
        </Flex>
      </Box> */}

      {/* {carddata?.title == "aisals" && (
        <VStack
          align="start"
          spacing="4"
          w={"100%"}
          m={"auto"}
          py={6}
          px={2}
          boxSizing={"border-box"}
        >
          <HStack spacing="2" w={"fit-content"} border={"1px solid white"}>
            <Image src={aiSalsman} boxSize="8" color="cyan.400" />
            <Text fontSize="xl" w={"fit-content"}>
              AI Salesmate
            </Text>
          </HStack>

          <HStack spacing="2">
            <Image src={aiaddfusion} boxSize="8" color="cyan.400" />
            <Text
              fontSize="xl"
              bgGradient="linear(to-r, rgba(72, 140, 239, 1), rgba(213, 72, 178, 1),rgba(255, 255, 255, 1))"
              bgClip={"text"}
            >
              AI AdFusion
            </Text>
          </HStack>

          <HStack spacing="2">
            <Image src={aicareconnect} boxSize="8" color="cyan.400" />
            <Text fontSize="xl">AI CareConnect</Text>
          </HStack>
        </VStack>
      )} */}
      <VStack
        align="start"
        spacing="4"
        w={"100%"}
        m={"auto"}
        py={6}
        px={8}
        boxSizing={"border-box"}
      >
        <HStack spacing="2" w={"fit-content"}>
          <Image src={aiSalsman} boxSize="8" color="cyan.400" />
          <Text fontSize="xl" w={"fit-content"}>
            AI Salesmate
          </Text>
        </HStack>

        <HStack spacing="2">
          <Image src={aiaddfusion} boxSize="8" color="cyan.400" />
          <Text
            fontSize="xl"
            bgGradient="linear(to-r, rgba(72, 140, 239, 1), rgba(213, 72, 178, 1),rgba(255, 255, 255, 1))"
            bgClip={"text"}
          >
            AI AdFusion
          </Text>
        </HStack>

        <HStack spacing="2">
          <Image src={aicareconnect} boxSize="8" color="cyan.400" />
          <Text fontSize="xl">AI CareConnect</Text>
        </HStack>
      </VStack>

      {/* Description Section */}
      <Box
        bgGradient="linear(to-r, #362071, #191B66)"
        borderRadius="lg"
        p={2}
        textAlign="start"
        boxSizing={"border-box"}
      >
        <Text fontWeight="semibold" fontSize="md">
          Our Vertical Agents
        </Text>
        <Text fontSize="sm">
          Leverage our vertical agents to deliver domain-specific solutions and
          optimize workflows effortlessly.
        </Text>
        <Box textAlign="right" boxSizing={"border-box"} p={0} m={0}>
          <Button
            fontSize={"xs"} // Small font size
            bgGradient={
              "linear(to-r, rgba(156, 77, 255, 1), rgba(44, 65, 254, 1))"
            }
            _hover={{ bg: "blue.500" }}
            color={"white"}
            borderRadius={"20px"}
            fontWeight={400}
            w={"fit-content"}
            px={3} // Reduced horizontal padding
            py={1} // Reduced vertical padding
          >
            Know more
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default VerticalAgents;
