// Pricing.jsx
import { Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import PricingTitle from "./PricingTitle";
import PricingDetails from "./PricingDetails";
import blacksquer from "../../Assets/pricingpage/darkmode/blacksquer.png";
import blackRing from "../../Assets/pricingpage/darkmode/blackRing.png";
import PricingCostDetails from "./pricingDetails/PricingCostDetails";
import WaveFlow from "./darkmode/WaveFlow/WaveFlow";

function WaveMain() {
  return (
    <Box boxSizing="border-box" bg={"black"}>
      <Box
        boxSizing="border-box"
        position="relative"
        mt="50px"
        mb="100px"
        bg={"black"}
        backgroundColor="transparent"
        zIndex="1"
      >
        <WaveFlow />
        <Box
          as="img"
          src={blacksquer}
          alt="Animated Ring"
          position="absolute"
          top="20"
          right="30"
          width="140px"
          height="140px"
          zIndex="-1"
        />
        <Box
          as="img"
          src={blackRing}
          alt="Animated Ring"
          position="absolute"
          bottom="10"
          left="20"
          width="150px"
          height="150px"
          zIndex="-1"
        />
      </Box>
    </Box>
  );
}

export default WaveMain;
