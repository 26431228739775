import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import ConersationAiCard from "./ConersationAiCard";

const ConversationAi = () => {
  return (
    <Box
      color="white"
      minH="100vh"
      p={6}
      bg={"black"}
      background={"transparent"}
      backdropFilter="blur(10px)"
    >
      <Box
        backgroundColor="transparent"
        backdropFilter="blur(10px)"
        textAlign="center"
        mb={8}
        color={"rgba(185, 189, 198, 1)"}
      >
        <Heading fontSize="md" mb={2}>
          LINE for Enterprise Search
        </Heading>
        <Text fontSize={"small"}>
          Agent Analytics proprietary technology with global optimization
          (larger content) with LINE citation technology
        </Text>
      </Box>
      <Flex
        direction={["column", "column", "row"]}
        justify="center"
        align="center"
        gap={1}
        w={"80vw"}
        margin={"auto"}
      >
        <ConersationAiCard />
      </Flex>
    </Box>
  );
};

export default ConversationAi;
