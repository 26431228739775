import {
  SimpleGrid,
  GridItem,
  Box,
  Text,
  Flex,
  VStack,
  Grid,
} from "@chakra-ui/react";
import SinglePartner from "./SinglePartner";
import { data } from "./partens";
import UspBox from "./UspBox";

function ContentGrid() {
  return (
    <Box
      boxSizing={"border-box"}
      width={"100%"}
      m={"auto"}
      mb={"30px"}
      mt={"50px"}
      bg={"black"}

    >
      <Box textAlign={"center"} fontWeight={600} fontSize={"xx-large"}>
        <Text as={"spane"} color={"white"}>
          Why Partner with{" "}
          <Text
            as={"span"}
            bgGradient={"linear(to-r,#8860D0, #5AB9EA)"}
            bgClip={"text"}
          >
            AgentAnalytics?
          </Text>
        </Text>
      </Box>
      <VStack>
        <Grid
       
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
          h={"100%"}
          gap={4}
          w={"90%"} 
          margin={"auto"}
          boxSizing={"border-box"}
          mt={{ base: "20px", md: "45px" }}
        >
          {data &&
            data.slice(0, 4).map((usp, index) => (
              <GridItem key={index} w="100%">
                <UspBox usp={usp} index={index} />
              </GridItem>
            ))}
        </Grid>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }}
          gap={4}
          h={"90%"}
          w={"70%"}
          boxSizing={"border-box"}
          mt={{ base: "20px", md: "45px" }}
        >
          {data &&
            data.slice(4, data.length).map((usp, index) => (
              <GridItem key={index} m={"auto"} h={"100%"} w={"100%"}>
                <UspBox usp={usp} index={index} />
              </GridItem>
            ))}
        </Grid>

        {/* <Flex gap={4}>
        {data.slice(0, 4).map((el) => (
          <SinglePartner item={el} />
        ))}
      </Flex> */}
        {/* <Flex  gap={4} m={"auto"} w={"80%"}>
        {data.slice(4, data.length).map((el) => (
          <SinglePartner item={el} />
        ))}
      </Flex> */}
      </VStack>
    </Box>
  );
}

export default ContentGrid;
