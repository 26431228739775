export const jobData=
    {
        primaryResponsibilities: [
          "Design, develop, and maintain full-stack applications using MERN (MongoDB, Express.js, React.js, Node.js) technologies.",
          "Collaborate with cross-functional teams to understand requirements and translate them into functional code.",
          "Write clean, efficient, and well-documented code.",
          "Ensure the performance, quality, and responsiveness of applications.",
          "Participate in code reviews and contribute to team knowledge sharing.",
          "Stay up-to-date with emerging technologies and industry best practices."
        ],
        jobSpecifications: [
          "4-8 years of experience in full-stack development using MERN stack.",
            "Strong proficiency in JavaScript, TypeScript, HTML, and CSS.",
            "Experience with RESTful APIs and web services.",
            "Knowledge of database development in MongoDB.",
            "Strong knowledge of version control systems, particularly Git.",
            "Excellent problem-solving skills and attention to detail.",
            "Knowledge of web security best practices.",
            "Experience with performance optimization techniques.",
            "Excellent collaboration skills for working effectively in a team environment.",
            "Ability to communicate technical concepts to non-technical stakeholders."
          
        ],
        goodToHave: [
          "Experience with state management libraries (Redux, Context API).",
          "Knowledge of CI/CD pipelines.",
          "Familiarity with cloud platforms (AWS, Azure)."
        ],
        benefits: [
          "Opportunity to work in T-Hub, the world’s largest innovation center, surrounded by a vibrant tech community.",
          "ESOPs (Employee Stock Ownership Plans) upon company funding, allowing you to share in the success and growth of the startup.",
          "Professional development opportunities and a supportive work culture fostering learning and innovation."
        ]
      }
      
