import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Button, Stack, useBreakpointValue } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const jobTableHeading = ["Job Role", "Location", "Site", "Date", "Action"];
const jobDetails = [
    {
        id: uuidv4(),
        jobRole: "Full Stack MERN Developer",
        Location: 'Hyderabad, India',
        Site: "On-site",
        Date: "Sep 06,2024",
    },
    {
        id: uuidv4(),
        jobRole: "Generative AI Architect",
        Location: 'Hyderabad, India',
        Site: "On-site",
        Date: "Sep 06,2024",
    }
];

const ItJob = () => {
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box width="100%" overflowX="auto">
            <Table variant="unstyled" width="100%">
                <Thead>
                    <Tr>
                        {jobTableHeading.map((el) => (
                            <Th
                                key={el}
                                borderBottom="1px solid"
                                borderColor="gray.300"
                                fontSize={isMobile ? "sm" : "md"}
                            >
                                {el}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {jobDetails.map((job) => (
                        <Tr key={job.id}>
                            <Td borderBottom="1px solid" borderColor="gray.300">{job.jobRole}</Td>
                            <Td borderBottom="1px solid" borderColor="gray.300">{job.Location}</Td>
                            <Td borderBottom="1px solid" borderColor="gray.300">{job.Site}</Td>
                            <Td borderBottom="1px solid" borderColor="gray.300">{job.Date}</Td>
                            <Td borderBottom="1px solid" borderColor="gray.300">
                                <Button
                                    onClick={() => navigate("/jobAplyPage")}
                                    color="white"
                                    bgGradient="linear(to-r, rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
                                    variant="solid"
                                    size={isMobile ? "sm" : "md"}
                                >
                                    Apply
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export default ItJob;
