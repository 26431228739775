import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  MenuDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import PriceListing from "./PriceListing";
import EnterpriseImage from "./EnterpriseImage";

function PriceDetailsComponent({ data ,toggle}) {
    
  return (
    <Box  h={"100%"} w={"100%"} boxSizing="border-box">
      <VStack
        h="100%"
        w={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        
      >
        <Box w="100%">
          <Box
            mb={"15px"}
            w="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <HStack>
            <Text color="#FFFFFF" fontSize="20px" fontWeight={500}>
              {data.title}
            </Text>
            <Image
            src={""} 
            />

            </HStack>
            
            <HStack w={"100%"}>
              {data.buttonTitle == "Contact Sales" ? (
                <Text color="#8A94A8" fontSize="14px" mr={"5%"}>
                  Custom Pricing{" "}
                </Text>
              ) : (
                ""
              )}
              {data.buttonTitle == "Contact Sales" ? (
                <EnterpriseImage />
              ) : (
                <Text color="#8A94A8" fontSize="14px">
                    {toggle?`${data.monthly}/mo`:`${data.annualy}/mo`}
                    {/* {data.prize}/mo{" "} */}
                </Text>
              )}
            </HStack>
          </Box>
          <Divider borderColor="#CED1D7" />
          <Box mt={"15px"} w="100%">
            <PriceListing packageAvailability={data.packageAvailability} />
          </Box>
        </Box>
        <Box
          w="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {data.buttonTitle == "Contact Sales" ? (
            <Button
              bgGradient="linear(to-r,rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
              boxShadow="
          inset rgba(0, 0, 0, 0.03) 0px 1px 2px, 
          inset rgba(0, 0, 0, 0.04) 0px 2px 4px, 
          inset rgba(0, 0, 0, 0.03) 0px 4px 8px, 
          inset rgba(0, 0, 0, 0.04) 0px 8px 16px, 
          inset rgba(0, 0, 0, 0.03) 0px 16px 32px, 
          inset rgba(0, 0, 0, 0.04) 0px 32px 64px"
              background="transparent"
              color="#FFFFFF"
              backgroundColor="transparent"
              size="sm"
              border="1px solid #C1C8E4"
              w="100%"
            >
              {data.buttonTitle}
            </Button>
          ) : (
            <Button
              boxShadow="
        inset rgba(0, 0, 0, 0.03) 0px 1px 2px, 
        inset rgba(0, 0, 0, 0.04) 0px 2px 4px, 
        inset rgba(0, 0, 0, 0.03) 0px 4px 8px, 
        inset rgba(0, 0, 0, 0.04) 0px 8px 16px, 
        inset rgba(0, 0, 0, 0.03) 0px 16px 32px, 
        inset rgba(0, 0, 0, 0.04) 0px 32px 64px"
              background="blur"
              color="#FFFFFF"
              backgroundColor="transparent"
              size="sm"
              border="1px solid #C1C8E4"
              w="100%"
            >
              {data.buttonTitle}
            </Button>
          )}
        </Box>
      </VStack>
    </Box>
  );
}

export default PriceDetailsComponent;
