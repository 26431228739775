import par1 from "../../Assets/partenrs/partener/par1.png"
import par2 from "../../Assets/partenrs/partener/par2.png"
import par3 from "../../Assets/partenrs/partener/par3.png"
import par4 from "../../Assets/partenrs/partener/par4.png"
import par5 from "../../Assets/partenrs/partener/par5.png"
import par6 from "../../Assets/partenrs/partener/par6.png"
import par7 from "../../Assets/partenrs/partener/par7.png"



 export const data = [
    {
      id: 1,
      title: "Ahead of the Curve",
      description:
        "All our content marketing service packages include a custom content strategy.",
      icon: par1,
    },
    {
      id: 2,
      title: "Enterprise-Ready",
      description:
        "We create some content calendar for your company's must-share content.",
      icon: par2,
    },
    {
      id: 3,
      title: "Competitive Pricing",
      description:
        "Experienced in copywriting and marketing team begins creating content",
      icon: par3,
    },
    {
      id: 4,
      title: "Hyper-Personalised AI",
      description: "Your content marketing management services also include SEO",
      icon: par4,
    },
    {
      id: 5,
      title: "Trusted AI",
      description: "Trusted AI's content marketing management includes SEO services to boost visibility, drive organic traffic, and enhance audience engagement.",
      icon: par5,
    },
    {
      id: 6,
      title: "LINE Technology",
      description:
        "Our unique LINE technology provides back-source referencing and explainability, fostering trust and confidence in AI-generated outputs. .",
      icon: par6,
    },
    {
      id: 7,
      title: "Experienced Team",
      description:
        "Our lean, agile team boasts deep technical and consulting expertise, with decades of experience delivering successful outcomes for Fortune 500 companies.",
      icon: par7,
    },
  ];