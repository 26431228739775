import React from "react";
import ring from "../../Assets/landingpage/footer/ring.png";
import Footer from "./Footer";
import { Box } from "@chakra-ui/react";

function FooterMain() {
  return (
    <Box position="relative" zIndex={-1} h="60vh" background="black">
  <Footer />
  <Box
    as="img"
    src={ring}
    alt="Animated Ring"
    position="absolute"
    bottom={{ base: "5", md: "10" }} // Adjust for small and medium screens
    top={{ base: "0", md: "-5" }} // Adjust for small and medium screens
    width={{ base: "150px", md: "250px", lg: "300px" }} // Responsive widths
    height={{ base: "150px", md: "250px", lg: "300px" }} // Responsive heights
    zIndex="1"
  />
</Box>
  );
}

export default FooterMain;
