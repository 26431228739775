import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Image } from "@chakra-ui/react";
import rightIcon from "../../../Assets/pricingpage/rightIcon.png";
import crossIcon from "../../../Assets/pricingpage/darkmode/crossIcon.png";

const ComparisonTable = () => {
  return (
    <Box
      backdropFilter="blur(10px)"
      bg="transparent"
      w="90%"
      overflowX="auto" // Ensure scrollable content on smaller screens
      p={[2, 4]} // Responsive padding
    >
      <Table variant="simple" colorScheme="whiteAlpha">
        <Thead fontSize={["xs", "sm"]} boxSizing="border-box">
          <Tr>
            <Th></Th>
            <Td pb="4px" boxSizing="border-box">
              <Box
                bg="rgba(140, 69, 255, 0.8)"
                color="white"
                borderRadius="lg"
                p={2}
                h="100%"
                w="100%"
              >
                Enterprise Search
              </Box>
            </Td>
            <Td pb="4px" boxSizing="border-box" textAlign="center">
              <Box
                boxShadow="inset rgba(255, 255, 255, 0.03) 0px 1px 2px, 
              inset rgba(255, 255, 255, 0.04) 0px 2px 4px, 
              inset rgba(255, 255, 255, 0.03) 0px 4px 8px, 
              inset rgba(255, 255, 255, 0.04) 0px 8px 16px, 
              inset rgba(255, 255, 255, 0.03) 0px 16px 32px, 
              inset rgba(255, 255, 255, 0.04) 0px 32px 64px"
                color="white"
                borderRadius="lg"
                p={2}
                border="1px solid gray"
                bg="rgba(61, 61, 61, 0.4)"
              >
                Perplexity
              </Box>
            </Td>
            <Td pb="4px" boxSizing="border-box" textAlign="center">
              <Box
                boxShadow="inset rgba(255, 255, 255, 0.03) 0px 1px 2px, 
              inset rgba(255, 255, 255, 0.04) 0px 2px 4px, 
              inset rgba(255, 255, 255, 0.03) 0px 4px 8px, 
              inset rgba(255, 255, 255, 0.04) 0px 8px 16px, 
              inset rgba(255, 255, 255, 0.03) 0px 16px 32px, 
              inset rgba(255, 255, 255, 0.04) 0px 32px 64px"
                color="white"
                borderRadius="lg"
                p={2}
                border="1px solid gray"
                bg="rgba(61, 61, 61, 0.4)"
              >
                MSAI Search
              </Box>
            </Td>
          </Tr>
        </Thead>
        <Tbody fontSize={["xs", "sm"]}>
          <Tr alignItems={"center"}>
            <Td>Document Relevancy</Td>
            <Td textAlign={"center"}>Global</Td>
            <Td textAlign={"center"}>Local</Td>
            <Td textAlign={"center"}>Index</Td>
          </Tr>
          <Tr>
            <Td>Citation</Td>
            <Td textAlign={"center"}>
              <Image
                src={rightIcon}
                alt="icon"
                display="inline"
                w="16px"
                mr={1}
              />
            </Td>
            <Td textAlign={"center"}>
              <Image
                src={crossIcon}
                alt="icon"
                display="inline"
                w="16px"
                mr={1}
              />
            </Td>
            <Td textAlign={"center"}>
              <Image
                src={crossIcon}
                alt="icon"
                display="inline"
                w="16px"
                mr={1}
              />
            </Td>
          </Tr>
          <Tr>
            <Td>RAG Request</Td>
            <Td textAlign={"center"}>No</Td>
            <Td textAlign={"center"}>Yes</Td>
            <Td textAlign={"center"}>No</Td>
          </Tr>
          <Tr>
            <Td>Multi Agentic</Td>
            <Td textAlign={"center"}>Yes</Td>
            <Td textAlign={"center"}>No</Td>
            <Td textAlign={"center"}>No</Td>
          </Tr>
          <Tr>
            <Td>Data Format</Td>
            <Td textAlign={"center"}>CSV, JSON, Text, PDF</Td>
            <Td textAlign={"center"}>CSV, JSON, Text, PDF</Td>
            <Td textAlign={"center"}>-</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default ComparisonTable;
