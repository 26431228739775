import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import VerticalAgents from "./VerticalComp";
import { border } from "@chakra-ui/react";

let data = [{ title: "aisals" ,img:""},{ title: "Waveflow" ,img:""},{ title: "Vector Lake" ,img:""} ];

const CarouselComponent = () => {
  return (
    <div
      style={{
        height: "50%",
        width: "100%",
        display: "flex",
        border: "1px solid pink",
      }}
    >
      <Carousel
        autoPlay={true}
        // showArrows={false}
        showIndicators={true}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        stopOnHover={false}
        width={"100%"}
        axis="vertical"
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const indicatorStyle = {
            backgroundColor: isSelected ? "white" : "transparent",
            borderRadius: "50%",
            border: "1px solid gray",
            width: "10px",
            height: "10px",
            margin: "10px 0",
            cursor: "pointer",
          };

          return (
            <li
              style={indicatorStyle}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              aria-label={`Slide ${index + 1}`}
              aria-selected={isSelected}
            />
          );
        }}
      >
        {data.map((word, index) => (
          <div key={index} style={{ color: "white", padding: "20px" }}>
            <VerticalAgents />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
