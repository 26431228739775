import {
  Box,
  Text,
  Flex,
  Heading,
  Badge,
  Button,
  Image,
} from "@chakra-ui/react";
import EnterpriseReady from "../../Assets/landingpage/banner/EnterpriseReady.png";

function UspTitle() {
  return (
    <Box
      //   bgGradient="linear(to-r, #000, #1a1a1a)"
      color="white"
      bg={"transparent"}
      margin={"auto"}
      //   mt={"40px"}
      position={"relative"}
      zIndex={10}
      //   py="20"
      //   px={{ base: "4", md: "8", lg: "16" }}
    >
      {/* Feature Badge */}
      <Button
        color={"white"}
        bg={"transparent"}
        border={"1px solid rgba(63, 63, 63, 1)"}
        borderRadius={"lg"}
        boxSizing={"border-box"}
      >
        <Image src={EnterpriseReady} mr={"10px"} /> Our Features
      </Button>

      {/* Main Content */}
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
      >
        {/* Heading Section */}
        <Text
          fontSize="30px"
          mb={{ base: "6", md: "0" }}
          fontWeight={500}
          lineHeight="short"
        >
          What makes us <br />
          <Text as="span">Different?</Text>
        </Text>

        {/* Subheading Section */}
        <Text
          fontSize="sm"
          maxW="sm"
          //   textAlign={{ base: "center", md: "right" }}
        >
          Our commitment to innovation, precision, and tailor-made solutions
          sets us apart.
        </Text>
      </Flex>
    </Box>
  );
}

export default UspTitle;
