import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import blackLogo from "../../Assets/logo/logoWithBlackLine.png";
import SocialLink from "./SocialLink";
import FooterLinks from "./FooterLinks";

function Footer() {
  return (
    <Box
      position={"relative"}
      zIndex={2}
      margin={"auto"}
      w="80%"
      borderRadius={"20px"}
      h={"60vh"}
      pt={"65px"}
      boxSizing={"border-box"}
      
    >
      <Box
        // h={"90%"}
        // p="30px"
        backdropFilter="blur(8px) saturate(150%)"
        borderRadius={"20px"}
        // bg={"transparent"}
        boxSizing={"border-box"}
        border={"1px solid rgba(63, 63, 63, 1)"}
        bg={{ base: "black", md: "transparent" }}
      >
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={5}
            w={{ base: "100%", md: "40%" }}
            pt="25px"
            pb="10px"
            pl={{ base: "0", md: "40px" }}
            textAlign={{ base: "center", md: "left" }}
            boxSizing={"border-box"}
          >
            <Image w="180px" src={blackLogo} mx={{ base: "auto", md: "0" }} />
            <Text color={"white"} fontSize={"sm"}>
              Our platform brings together cutting-edge agentic technology to
              optimize workflows effortlessly
            </Text>
            <Box mt={"15%"}>
              <SocialLink />
            </Box>
          </Box>
          <Box
            boxSizing={"border-box"}
            w={{ base: "100%", md: "50%" }}
            pt="25px"
            pl={{ base: "0", md: "40px" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <FooterLinks />
          </Box>
        </Flex>
      </Box>
      <Box
        py="5px"
        bg={"transparent"}
        textAlign="center"
        borderBottomEndRadius={"15px"}
        borderBottomStartRadius={"15px"}
        boxSizing={"border-box"}
      >
        <Text color="#ffffff" fontSize="sm">
          © All Right Reserved. 2024. AgentAnalytics.AI
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
