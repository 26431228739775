// Pricing.jsx
import { Box, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import PricingTitle from "../../PricingTitle";
import WaveFlowDetails from "./WaveFlowDetails";
import WaveFlowTitle from "./WaveflowTitle";

function WaveFlow() {
  const [toggle, setToggle] = useState(true);
  const billingToggle = () => {
    setToggle(!toggle);
  };
  return (
    <Box boxSizing="border-box" bg={"black"} background={"transparent"}>
      <Box
        bg={"black"}
        w={"98%"}
        m={"top"}
        boxSizing="border-box"
        position="relative"
        backgroundColor="transparent"
      >
        <WaveFlowTitle setToggle={setToggle} toggle={toggle} />
        <Box
          boxSizing="border-box"
          position="relative"
          mt="20px"
          ml={"2%"}
          mb={"25px"}
          backgroundColor="transparent"
        >
          <WaveFlowDetails/>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="20px" 
        >
          {/* <Box
            display="inline-flex"
            alignItems="center"
            borderRadius="md" 
          >
            <Switch onChange={billingToggle} size="sm" />
            <FormLabel
              ml={2}
              fontSize="14px"
              color="#3D475C"
              htmlFor="bill"
              mb="0"
            >
              {toggle ? "Billed Monthly" : "Billed Annually"}
            </FormLabel>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}

export default WaveFlow;
