export const footerData = {
  //   Product: [
  //     { title: "Features", redirectlink: "#" },
  //     { title: "FAQ", redirectlink: "#" },
  //     { title: "Pricing", redirectlink: "/pricing" },
  //   ],
  Company: [
    { title: "Home", redirectlink: "#" },
    { title: "About us", redirectlink: "/career" },
    { title: "Features", redirectlink: "#" },
  ],
  Pricing: [
    { title: "Waveflow", redirectlink: "#" },
    { title: "Conversational Search", redirectlink: "#" },
    { title: "Conversational AI", redirectlink: "#" },
  ],
  //   Legal: [
  //     { title: "Privacy", redirectlink: "#" },
  //     { title: "Terms", redirectlink: "#" },
  //     { title: "Security", redirectlink: "#" },
  //   ],
};
