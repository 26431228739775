import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import JobDetailDataApply from './JobDetailDataApply'

function JobApplyPage() {
    const ref = useRef(null)
    const [customHeight, setCustomHeight] = useState(0)

    useEffect(() => {
        if (ref.current) {
            console.log("height", ref.current.offsetHeight)
            setCustomHeight(Math.round(ref.current.offsetHeight) )
        }
    }, [ref])

    return (
        <Box mb={`${customHeight}px`}>
            <Box
                background="white"
                w="100%"
                h="250px"
                bgGradient='linear(to-r, purple.500, blue.400)'
                position="relative"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Box mt={0} mb={"50px"} textAlign="center" color="white">
                    <Text fontWeight={500} fontSize="30px">Full Stack MERN Developer</Text>
                    <Text fontSize="20px">AgentAnalytics.AI</Text>
                </Box>

                <Box
                    ref={ref}
                    background="white"
                    borderRadius="10px"
                    border="1px solid lightgray"
                    top="170px"
                    left="40px"
                    right="40px"
                    w="90%"
                    m="auto"
                    position="absolute"
                    zIndex={1} 
                >
                    <JobDetailDataApply />
                </Box>
            </Box>
        </Box>
    )
}

export default JobApplyPage
