import {
  Box,
  Button,
  Center,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ellips from "../../Assets/homepage/industries/ellips.png";
import industreis1 from "../../Assets/homepage/industries/industries1.png";

function IndustriesBox({ categories, index }) {
  const [MouseHoverEffect, setMouseHoverEffect] = useState(null);

  return (
    <VStack
      spacing={0}
      h={"90%"}
      boxSizing={"border-box"}
      mb={"40px"}
      w={"100%"}
      borderRadius={"10px"}
      justifyContent={"space-between"}
      onMouseEnter={() => setMouseHoverEffect(index)}
      onMouseLeave={() => {
        setMouseHoverEffect(null);
      }}
      transition={"transform 0.3s ease , background 0.3s ease"}
      transform={MouseHoverEffect == index ? "scale(1.1)" : "scale(1)"}
      boxShadow={
        "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
      }
    >
      <Box
        h={"32%"}
        w={"100%"}
        backgroundImage={`url(${ellips})`}
        // backgroundPosition={"top"}
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat={"no-repeat"}
        textAlign={"center"}
        alignItems={"center"}
        borderRadius={"10px"}
      >
        <Center>
          <Box
            boxSizing={"border-box"}
            bg={"#ffffff"}
            borderRadius={"50%"}
            p={"5px"}
            mt={"20%"}
          >
            <Image w={"70px"} src={categories.icon} />
          </Box>
        </Center>
      </Box>
      <Box w="100%" mb={"15px"} boxSizing={"border-box"}>
        <Text
          mb={"12px"}
          boxSizing={"border-box"}
          fontWeight={500}
          fontSize={"20px"}
          textAlign={"center"}
        >
          {categories.title}
        </Text>
        <UnorderedList
          lineHeight={"25px"}
          boxSizing={"border-box"}
          pl={"15px"}
          textAlign="left"
        >
          {categories.items &&
            categories.items.map((ele) => (
              <ListItem color={"#78847D"} fontSize={"16px"}>
                {ele}
              </ListItem>
            ))}
        </UnorderedList>
      </Box>
      {/* <Box
        boxSizing={"border-box"}
        pr={"25px"}
        pb={"10px"}
        w={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button
          isDisabled={true}
          boxSizing={"border-box"}
          color={"#5680E9"}
          background={"#ffffff"}
          m={0}
          //    _hover={{p:4 ,background:"purple.300" , color:"#ffffff"}}
        >
          Learn more {">"}
        </Button>
      </Box> */}
    </VStack>
  );
}

export default IndustriesBox;
