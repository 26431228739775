import { Box, Button, Center, Flex, HStack, Text } from "@chakra-ui/react";
import React from "react";
import bannerImage from "../../Assets/partenrs/bannerimage.png";
import { mailtoLink } from "../../components/mailbox/mailbutton";

function Banner() {
  return (
    <Box
      width="100vw"
      height="75vh"
      // bg={"black"}
      boxSizing={"border-box"}
      // backgroundImage={`url(${bannerImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      alignItems={"center"}
      margin="0 auto"
      objectPosition={"center"}
      bg="linear-gradient(to top right, black, black ,#19113F, #19113F, black, black)"
      color={"white"}

    >
      <Center height="100%">
        <Flex direction={"column"} textAlign={"center"} alignItems={"center"}>
          <Box>
            <Text fontSize={"45px"} fontWeight={700}>
              Join AgentAnalytics.AI{" "}
              <span style={{ color: "rgba(102, 102, 102, 1)" }}>
                and Lead <br /> the Generative AI Revolution
              </span>{" "}
            </Text>
          </Box>
          <HStack boxSizing={"border-box"} mt={"30px"} spacing={6}>
            <Button
              as="a"
              href={mailtoLink}
              bgGradient="linear(to-r, rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
              borderRadius={"20px"}
              px={8}
              color={"white"}
              boxSizing={"border-box"}
              _hover={{
                bgGradient: "linear(to-r, teal.300, blue.500)",
                boxShadow: "lg",
                cursor: "pointer",
              }}
            >
              Contact Us
            </Button>
            <Button
              as="a"
              href={mailtoLink}
              _hover={{
                bgGradient: "linear(to-r,purple.500,blue.500)",
                boxShadow: "lg",
                color: "white",
                cursor: "pointer",
              }}
              boxSizing={"border-box"}
              borderRadius={"20px"}
              px={8}
            >
              Get a Demo
            </Button>
          </HStack>
        </Flex>
      </Center>
    </Box>
  );
}

export default Banner;
