import { Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

function UspBox({ usp, index }) {
  const [MouseHoverEffect, setMouseHoverEffect] = useState(null);
  return (
    <Box
      position={"relative"}
      zIndex="-1"
      transition={"transform 0.3s ease, background-color 0.3s ease"}
      transform={MouseHoverEffect === index ? "scale(1.1)" : "scale(1)"}
      onMouseEnter={() => setMouseHoverEffect(index)}
      onMouseLeave={() => setMouseHoverEffect(null)}
      background={
        MouseHoverEffect == index ? "rgba(90, 185, 234, 0.2)" : "transparent"
      }
      w={"100%"}
      p={"25px"}
      border="1px solid lightgray"
      boxShadow={
        "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px"
      }
      borderRadius={"10px"}
      h={"100%"}
      boxSizing={"border-box"}
    >
      <VStack
        h={"100%"}
        display={"flex"}
        direction={"column"}
        justifyContent={"space-between"}
        spacing={4}
        align={"start"}
      >
        <Image w={"50px"} src={usp.icon} />

        <Box>
          <Text fontWeight={700} fontSize={"20px"}>
            {usp.title}
          </Text>
          <Text color={"#78847D"} fontSize={"14px"}>
            {usp.description}
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default UspBox;
