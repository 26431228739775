import React from "react";
import AllTasksDepartment from "./AllTasksDepartment";
import { Box } from "@chakra-ui/react";

function JobDescription() {
  return (
    <Box>
      <AllTasksDepartment />
    </Box>
  );
}

export default JobDescription;
