import { Box, Button, Heading, HStack, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { BackdropExample } from '../../components/Test/Test'
import ApplyButton from './ApplyButton'
import { ApplyNowBtn } from './ApplyNowBtn'
import {jobData} from "./jobData.js"
const employementData=[
    {
        type:"Employment Type",
        type1:"Full-time"
    },
    {
        type:"Work place Type:",
        type1:"In Office"
    },
    {
        type:"Salary:",
        type1:"Commensurate with experience and skills"
    },
    {
        type:"Experience Required:",
        type1:"Minimum 4 Years"
    }
]

function JobDetailDataApply() {
    return (
        <Box bg={"white"} border={"1px solid lightgray"} borderWidth="1px" borderRadius="lg" pt={50} px={50} pb={10} maxW="100%" mx="auto">
            <Box mb={"25px"} display={"flex"} justifyContent={"space-between"}>
                <Box>
                    <ApplyButton/>
                </Box>
                <Box id='applynowbtn'>
                    <ApplyNowBtn/>
                </Box>
            </Box>
            <Box mb={"25px"}>
                <Heading as='h2' size='md'>Full Stack MERN Developer</Heading>
                <Text fontSize={"14px"} color={"rgba(79, 86, 101, 1)"}>Hyderabad, India</Text>
                <Text fontSize={"14px"} color={"rgba(79, 86, 101, 1)"}>Gurgaon, Delhi</Text>
            </Box>
            <Box mb={"25px"}>
                <HStack>
                    <Box  textAlign={'center'} px={1} >
                        <HStack spacing={2}>
                        <Text p={1} background={"rgba(136, 96, 208, 0.3)"} color={"rgba(61, 71, 92, 1)"}>Mid-level</Text>
                        <Text p={1} background={"rgba(136, 96, 208, 0.3)"} color={"rgba(61, 71, 92, 1)"}>Hyderbad</Text>
                        </HStack>
                        
                    </Box>
                </HStack>
            </Box>
            <Box>
                <Text color={"rgba(79, 86, 101, 1)"} fontWeight={"bold"} fontSize={"16px"}>
                    Primary Responsibility:
                </Text>
                <Box>
                    <UnorderedList>
                        {
                            jobData.primaryResponsibilities && jobData.primaryResponsibilities.map((el)=>(
                                <ListItem mb={"3px"}  color={"rgba(79, 86, 101, 1)"}>{el}</ListItem>

                            ))
                        }
                    </UnorderedList>
                </Box>
            </Box>
            <Box>
                <Text color={"rgba(79, 86, 101, 1)"} fontWeight={"bold"} fontSize={"16px"}>
                Job Specification:
                </Text>
                <Box>
                    <UnorderedList>
                    {
                            jobData.jobSpecifications && jobData.jobSpecifications.map((el)=>(
                                <ListItem mb={"3px"}  color={"rgba(79, 86, 101, 1)"}>{el}</ListItem>

                            ))
                        }
                    </UnorderedList>
                </Box>
            </Box>
            <Box>
                <Text color={"rgba(79, 86, 101, 1)"} fontWeight={"bold"} fontSize={"16px"}>
                Good To Have
                </Text>
                <Box>
                    <UnorderedList>
                    {
                            jobData.goodToHave && jobData.goodToHave.map((el)=>(
                                <ListItem mb={"3px"}  color={"rgba(79, 86, 101, 1)"}>{el}</ListItem>

                            ))
                        }
                    </UnorderedList>
                </Box>
            </Box>
            <Box>
                <Text color={"rgba(79, 86, 101, 1)"} fontWeight={"bold"} fontSize={"16px"}>
                Benefits
                </Text>
                <Box>
                    <UnorderedList>
                    {
                            jobData.benefits && jobData.benefits.map((el)=>(
                                <ListItem mb={"3px"}  color={"rgba(79, 86, 101, 1)"}>{el}</ListItem>

                            ))
                        }
                    </UnorderedList>
                </Box>
            </Box>
            <Box mt={"30px"}>
                {employementData && employementData.map((el)=>(
                    <Box>
                    <Text fontWeight={"500"}  fontSize={"18px"} mb={"2px"}>{el.type}</Text>
                    <Text color={"rgba(79, 86, 101, 1)"}>{el.type1}</Text>
                </Box>
                ))}
                
            </Box>
                <Box mt={"50px"}>
                <BackdropExample/>
                </Box>

        </Box>
    )
}

export default JobDetailDataApply