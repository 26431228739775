import React from "react";
import { List, ListItem, Image, Link, Box, HStack } from "@chakra-ui/react";
import insta from "../../Assets/landingpage/footer/insta.png";
import facbook from "../../Assets/landingpage/footer/facebook.png";
import linkdine from "../../Assets/landingpage/footer/linkdine.png";
import Twitter from "../../Assets/landingpage/footer/twitter.png";

const data = [
  {
    icon: insta,
  },
  {
    icon: facbook,
  },
  {
    icon: Twitter,
  },
  {
    icon: linkdine,
  },
];

function SocialLink() {
  return (
    <Box boxSizing={"border-box"}>
      <List display={"flex"} flexDirection={"column"}>
        <HStack spacing={6}>
          {data &&
            data.map((ele) => (
              <ListItem>
                <Link href="https://example.com/item1" isExternal>
                  <Image
                    src={ele?.icon}
                    alt="linkdine"
                    display="inline"
                    w={"35px"}
                  />
                </Link>
              </ListItem>
            ))}
        </HStack>
      </List>
    </Box>
  );
}

export default SocialLink;
