import {
  Box,
  Button,
  Divider,
  HStack,
  Image,
  MenuDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import WaveFlowListing from "./WaveFlowListing";
import EnterpriseImage from "../../EnterpriseImage";
//   import EnterpriseImage from "./EnterpriseImage";

function WaveFlowDetailsComponent({ data, toggle }) {
  return (
    <Box h={"100%"} w={"100%"} boxSizing="border-box" >
      <VStack
        h="100%"
        w={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box w="100%">
          <Box
            mb={"15px"}
            w="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <HStack>
              <Text color="#FFFFFF" fontSize="20px" fontWeight={500}>
                {data.title}
              </Text>
              <Image h={"40px"} src={data.tag} />
            </HStack>

            <HStack w={"100%"}>
              {data.buttonTitle == "Contact Sales" ? (
                <Text color="#8A94A8" fontSize="14px" mr={"5%"}>
                  Custom Pricing{" "}
                </Text>
              ) : (
                ""
              )}
              {data.prize == "Custom Pricing" ? (
                <>
                  <Text color={"rgba(138, 148, 168, 1)"}>{data.prize}</Text>
                  <EnterpriseImage />
                </>
              ) : (
                <Text color="#8A94A8" fontSize="14px">
                  {toggle ? `${data.monthly}` : `${data.annualy}`}
                  {/* {data.prize}/mo{" "} */}
                </Text>
              )}
            </HStack>
          </Box>
          <Divider borderColor="#CED1D7" />
          <Box mt={"15px"} w="100%">
            <WaveFlowListing packageAvailability={data.packageAvailability} />
          </Box>
        </Box>
        <Box
          w="100%"
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {data.buttonTitle == "Contact Sales" ? (
            <Button
              bgGradient="linear(to-r,rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
              boxShadow="
            inset rgba(0, 0, 0, 0.03) 0px 1px 2px, 
            inset rgba(0, 0, 0, 0.04) 0px 2px 4px, 
            inset rgba(0, 0, 0, 0.03) 0px 4px 8px, 
            inset rgba(0, 0, 0, 0.04) 0px 8px 16px, 
            inset rgba(0, 0, 0, 0.03) 0px 16px 32px, 
            inset rgba(0, 0, 0, 0.04) 0px 32px 64px"
              background="transparent"
              color="#FFFFFF"
              backgroundColor="transparent"
              size="sm"
              border="1px solid #C1C8E4"
              w="100%"
              _hover={{
                bg: "gray.400", // Change background on hover
                color: "black", // Change text color on hover
                transform: "scale(1.05)", // Slightly enlarge the button
                boxShadow: "0 4px 12px rgba(74, 144, 226, 0.4)", // Add shadow
              }}
            >
              {data.buttonTitle}
            </Button>
          ) : (
            <Button
              boxShadow="
            inset rgba(255, 255, 255, 0.03) 0px 1px 2px, 
            inset rgba(255, 255, 255, 0.04) 0px 2px 4px, 
            inset rgba(255, 255, 255, 0.03) 0px 4px 8px, 
            inset rgba(255, 255, 255, 0.04) 0px 8px 16px, 
            inset rgba(255, 255, 255, 0.03) 0px 16px 32px, 
            inset rgba(255, 255, 255, 0.04) 0px 32px 64px"
              background="rgba(61, 61, 61, 0.4)"
              color="#FFFFFF"
              backgroundColor="transparent"
              size="sm"
              border="1px solid #C1C8E4"
              w="100%"
              _hover={{
                bg: "gray.400", // Change background on hover
                color: "black", // Change text color on hover
                transform: "scale(1.05)", // Slightly enlarge the button
                boxShadow: "0 4px 12px rgba(74, 144, 226, 0.4)", // Add shadow
              }}
            >
              {data.buttonTitle}
            </Button>
          )}
        </Box>
      </VStack>
    </Box>
  );
}



export default WaveFlowDetailsComponent;
