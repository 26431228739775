"use client";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";

// import logo from "../Assets/navbar/logo.png";
import logo from "../Assets/logo/logoWithBlackLine.png";
import { NavLink, useNavigate } from "react-router-dom";
import { mailtoLink } from "./mailbox/mailbutton";

export default function Navbar() {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  const handleForDemo = () => {
    navigate("/demo");
  };

  return (
    <Box
      borderBottom={"1px solid rgba(149, 149, 149, 0.36)"}
      px={"20px"}
      bgGradient="linear(to-r, #19113F, #19113F,black,black,black,black)"
      boxSizing={"border-box"}
    >
      <Flex
        bgGradient="linear(to-r, #19113F, #19113F,black,black,black,black)"
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.900", "gray.900")}
        align={"center"}
        boxSizing={"border-box"}
        // bg="black"
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          // ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            color={"white"}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Box
            flex={{ base: "none", md: 1 }}
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"heading"}
            onClick={() => navigate("/")}
            cursor={"pointer"}
            color={useColorModeValue("gray.800", "white")}
          >
            <Image src={logo} w={"200px"} />
          </Box>

          <Flex flex={1} display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
          <Stack
            flex={1}
            // flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            // spacing={6}
            // border={"1px solid lightgray"}
            p={1}
            borderRadius={"10px"}
            boxSizing={"border-box"}
          >
            {/* <Button size={"sm"} as={'a'} bgGradient='linear(to-r, #8860D0, #5680E9)' fontSize={'sm'} fontWeight={600} borderRadius={"lg"}  _hover={{
              bg: '#8860D0',
            }}
            color={'white'} href={'#'}>
          Book a Demo
          </Button> */}
            <Button
              as="a"
              // href={mailtoLink}
              bgGradient="linear(to-r, #8860D0, #5680E9)"
              display={{ base: "none", md: "inline-flex" }}
              fontSize={"sm"}
              fontWeight={600}
              color={"white"}
              size={"sm"}
              _hover={{
                bg: "#8860D0",
              }}
              onClick={handleForDemo}
            >
              Book Enterprise Trial
            </Button>
          </Stack>
        </Flex>

        {/* <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}
          border={"1px solid lightgray"}
          p={1}
          borderRadius={"10px"}
          >
          <Button size={"sm"} as={'a'} bgGradient='linear(to-r, #8860D0, #5680E9)' fontSize={'sm'} fontWeight={600} borderRadius={"lg"}  _hover={{
              bg: '#8860D0',
            }}
            color={'white'} href={'#'}>
          Book a Demo
          </Button>
          <Button
            as={'a'}
            bgGradient='linear(to-r, #8860D0, #5680E9)'
            display={{ base: 'none', md: 'inline-flex' }}
            fontSize={'sm'}
            fontWeight={600}
            color={'white'}
            size={"sm"}

            href={'#'}
            _hover={{
              bg: '#8860D0',
            }}>

           Book a Demo
          </Button>
        </Stack> */}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("#FFFFFF", "#FFFFFF");
  const linkHoverColor = useColorModeValue("#FFFFFF", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack
      direction={"row"}
      spacing={8}
      border={"1px solid lightgray"}
      px={4}
      py={1}
      borderRadius={"20px"}
      bg={"transparent"}
      color={"#FFFFFF"}
      boxSizing={"border-box"}
    >
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label} color={"#FFFFFF"}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as={NavLink}
                to={navItem.href ?? "#"}
                p={2}
                fontSize={"md"}
                fontWeight={500}
                color={linkColor}
                boxSizing={"border-box"}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                _activeLink={{
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  fontWeight: "bold",
                  borderRadius: "10px",

                  borderBottom: "2px solid",
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                boxSizing={"border-box"}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      boxSizing={"border-box"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("gray.800", "gray.800")}
      p={5}
      display={{ md: "none" }}
      boxSizing={"border-box"}
      color={"white"}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        boxSizing={"border-box"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.400", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      {/* <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse> */}
    </Stack>
  );
};

const NAV_ITEMS = [
  // {
  //   label: 'About',
  //   // children: [
  //   //   {
  //   //     label: 'Explore Design Work',
  //   //     subLabel: 'Trending Design to inspire you',
  //   //     href: '#',
  //   //   },
  //   //   {
  //   //     label: 'New & Noteworthy',
  //   //     subLabel: 'Up-and-coming Designers',
  //   //     href: '#',
  //   //   },
  //   // ],
  // },
  {
    label: "Industries",
    href: "/industries",
    // children: [
    //   {
    //     label: 'Job Board',
    //     subLabel: 'Find your dream design job',
    //     href: '#',
    //   },
    //   {
    //     label: 'Freelance Projects',
    //     subLabel: 'An exclusive list for contract work',
    //     href: '#',
    //   },
    // ],
  },
  // {
  //   label: "Solutions",
  //   href: "/solution",
  // },
  {
    label: "Resources",
    href: "/resource",
  },
  {
    label: "Partners",
    href: "/partners",
  },
  {
    label: "Pricing",
    href: "/pricing",
  },
];
