import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Image,
  List,
  ListItem,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import leftArrow from "../../../../Assets/pricingpage/darkmode/leftArrow.png";

const waveFlowList = [
  "Enterprise platform for Business Process Automation",
  "Avoid RPA, Embrace Agentic Workflow Automation",
  "10x more efficient, 2x deeper, 4x faster",
];

function WaveFlowTitle({ setToggle, toggle }) {
  return (
    <Box w={"100%"} boxSizing="border-box">
      <VStack>
        <List flexDirection={"column"} justifyContent={"flex-start"}>
          {waveFlowList &&
            waveFlowList.map((ele) => (
              <ListItem
                color={"rgba(185, 189, 198, 1)"}
                lineHeight={"35px"}
                fontSize={"12px"}
                alignItems={"center"}
              >
                <Image
                  src={leftArrow}
                  alt="linkdine"
                  display="inline"
                  w={"16px"}
                  mr={1}
                />
                {ele}
              </ListItem>
            ))}
        </List>
        
      </VStack>
    </Box>
  );
}

export default WaveFlowTitle;
