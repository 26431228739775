import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import WaveFlowDetailsComponent from "./WaveFlowDetailsComponent";
import { pricingData } from "../../pricingData";

function WaveFlowDetails({ toggle }) {
  return (
    <Box
      backdropFilter="blur(10px)"
      boxSizing="border-box"
      w={["95%", "90%", "80%"]}
      m="auto"
      // p={[4, 6, 8]}
    >
      <Flex 
        direction={["column", "column", "row"]} 
        gap={[4, 6, 3]}
      >
        {["basic", "Buisness", "Enterprise"].map((key, index) => (
          <Box
            key={index}
            backdropFilter="blur(10px)"
            borderRadius="10px"
            flex={["unset", "unset", "1"]}
            w={["100%", "100%", "auto"]}
            p="15px"
            border="1px solid rgba(255, 255, 255, 0.15)"
            _hover={{
              boxShadow:
                "0px 2px 4px rgba(136, 96, 208, 1), " +
                "0px 6px 8px rgba(136, 96, 208, 1), " +
                "0px 6px 32px rgba(136, 96, 208, 1)",
            }}
          >
            <WaveFlowDetailsComponent toggle={toggle} data={pricingData[key]} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default WaveFlowDetails;
