import React from 'react'
import { List, ListItem, Link, Box, HStack, VStack, Flex } from '@chakra-ui/react';


function FooterLinksList({ data }) {
    console.log("data", data)
    return (
        <Box mt={3} boxSizing={"border-box"}>
            <List display={"flex"} flexDirection={"column"}>
                <Flex direction="column" alignItems="flex-start" gap={2}>
                    {
                        data && data.map((ele) => (
                            <ListItem key={ele.id}>
                                <Link fontSize={{ base: "12px", md: "16px" }}  href={ele.redirectlink} isExternal color="rgba(255, 255, 255, 0.5)">
                                    {ele.title}
                                </Link>
                            </ListItem>
                        ))
                    }
                </Flex>

            </List>
        </Box>
    )
}

export default FooterLinksList