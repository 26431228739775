import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import DemoPage from '../demo/Demo'

function Solution() {
  return <DemoPage/>
        
    
  
}

export default Solution