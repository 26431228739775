import React from "react";
import { Box, HStack, Image } from "@chakra-ui/react";
import sharebtn from "../../Assets/job/sharebtn.png";
import shareIcon from "../../Assets/job/shareIcon.png";

const ApplyButton = () => {
  return (
    <Box display="grid" placeItems="center" bg="#ffffff" w={"100%"} mt={-1}>
      <Box
        p="3px"
        w={"100%"}
        position="relative"
        bgGradient="linear(to-r, rgba(136, 96, 208, 1), rgba(86, 128, 233, 0.91))"
        borderRadius="8px" // Added border radius here
      >
        <Box
          w={"100%"}
          bg="#ffffff"
          color="black"
          px={"20px"}
          py={"10px"}
          borderRadius="6px" // Added border radius here as well
        >
          <HStack>
            <Image src={sharebtn} h={"12px"} />
            <Image src={shareIcon} w={"20px"} />
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

export default ApplyButton;
