import React from "react";
import IndustriesBox from "../../components/BoxStyles/IndustriesBox";
import { categories } from "./BannerData.js";
import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

function IndustrisWork() {
  return (
    <Box border={"1px solid black"} w={"100%"} m={"auto"}  bg={"black"} color={"white"}>
      <Box textAlign={"center"} w={"90%"} margin={"auto"} mb={"35px"}>
        <Text fontSize="45px" fontWeight={500}>
          <Text
            as="span"
            bgGradient="linear(to-r, #8860D0, #5AB9EA)"
            bgClip="text"
          >
            Industries
          </Text>{" "}
          we work with
        </Text>
        <Text mt={"10px"} fontWeight={500}>
          Cost-effective technology solutions that drive innovation and
          efficiency
        </Text>
      </Box>

      <Grid
        boxSizing="border-box"
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(3, 1fr)",
          lg: "repeat(4, 1fr)",
        }}
        gap={6}
        mt="45px"
        w={"90%"}
        margin={"auto"}
        mb={"50px"}
      >
        {categories &&
          categories.map((item, index) => (
            <GridItem w="100%" h={"350px"}>
              <IndustriesBox categories={item} index={index} />
            </GridItem>
          ))}
      </Grid>
    </Box>
  );
}

export default IndustrisWork;
